// Imports

import React from "react"
import { Link as GatsbyLink } from 'gatsby';
import { Link } from 'react-scroll';

import serviceCardStyles from "./card-service.module.css"

// Header

const ServiceCard = ({ service, newPage, tag }) => {
  const object = service.childMarkdownRemark.frontmatter
  if (newPage === true) {
    return (
      <GatsbyLink to="/services/">
        <div className={serviceCardStyles.flipContainer}>
          <div className={serviceCardStyles.flipper}>
            <div className={serviceCardStyles.front}>
              <img className={serviceCardStyles.img} src={object.image} alt="Service" />
              <h4 className={serviceCardStyles.titleFront}>{object.title}</h4>
            </div>
            <div className={serviceCardStyles.back}>
              <h4 className={serviceCardStyles.titleBack}>{object.title}</h4>
              <h5 className={serviceCardStyles.description}>{object.detail}</h5>
            </div>
          </div>
        </div>
      </GatsbyLink>
    )
  }
  return (
    <Link to={tag} spy={true} smooth={true} offset={-50} duration={400}>
      <div className={serviceCardStyles.flipContainer}>
          <div className={serviceCardStyles.flipper}>
            <div className={serviceCardStyles.front}>
              <img className={serviceCardStyles.img} src={object.image} alt="Service" />
              <h4 className={serviceCardStyles.titleFront}>{object.title}</h4>
            </div>
            <div className={serviceCardStyles.back}>
              <h4 className={serviceCardStyles.titleBack}>{object.title}</h4>
              <h5 className={serviceCardStyles.description}>{object.detail}</h5>
            </div>
          </div>
        </div>
    </Link>
  )
}

export default ServiceCard
