// Imports

import { useStaticQuery, graphql } from "gatsby"

// Hook

export const ServicesHook = () => {
  const query = useStaticQuery(
    graphql`
      query servicesQuery {
        allFile(filter: {sourceInstanceName: {eq: "content"}, relativeDirectory: {eq: "services"}}) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                title
                detail
                image
                order
                tests
              }
            }
          }
        }
      }
    }
    `
  )
  return query.allFile.edges
}